import { Box, Heading, Icon, VStack } from '@chakra-ui/react';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { RiRoadMapLine } from 'react-icons/ri';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import ReactLeafletKml from 'react-leaflet-kml';
import L from 'leaflet';

function Locate() {
  const [position, setPosition] = useState(null);

  const map = useMapEvents({
    click: () => {
      map.locate();
    },
    locationfound: location => {
      setPosition(location.latlng);
      map.flyTo(location.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>Your Current Location</Popup>
    </Marker>
  );
}

function LeafletMap() {
  const [kml, setKml] = useState(null);

  useEffect(() => {
    fetch('./ServiceArea.kml')
      .then(res => res.text())
      .then(kmlText => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, 'text/xml');
        setKml(kml);
      });

    // https://github.com/PaulLeCam/react-leaflet/issues/453

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }, []);

  return (
    <Box>
      <VStack marginTop={'5'}>
        <Icon as={RiRoadMapLine} boxSize={10} />
        <Heading textAlign={'center'} size={'md'}>
          Areas We Serve
        </Heading>
      </VStack>
      <MapContainer
        style={{ height: '500px', width: '100%' }}
        zoom={7}
        center={[39.9526, -75.1652]}
      >
        <Locate />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {kml && <ReactLeafletKml kml={kml} />}
      </MapContainer>
    </Box>
  );
}

export default LeafletMap;
