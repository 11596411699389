import { Center } from '@chakra-ui/react';
import { Divider } from '@chakra-ui/react';
import {
  Box,
  ChakraProvider,
  extendTheme,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import Hero from './components/Hero';
import LeafletMap from './components/LeafletMap';
import Navbar from './components/Navbar';
import Contact from './pages/Contact';
import Services from './pages/Services';

const theme = extendTheme({
  config: {
    useSystemColorMode: true,
    initialColorMode: 'dark',
  },
});

function App() {
  return (
    <ChakraProvider theme={theme} resetCSS>
      <Navbar />
      <Hero />
      <Box mx={5} marginTop={10}>
        <VStack spacing={10}>
          <Services />
          <Divider />
        </VStack>
        <SimpleGrid columns={{ base: 1, md: 2 }} mx={'15%'} gap={5} my={'.5%'}>
          <Center>
            <Contact />
          </Center>
          <Box>
            <Divider display={{ base: 'block', md: 'none' }} />
            <LeafletMap />
          </Box>
        </SimpleGrid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
