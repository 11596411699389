import {
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';

export default function WithBackgroundImage() {
  return (
    <Flex
      w={'full'}
      h={'60vh'}
      backgroundImage={'./banner.jpeg'}
      backgroundSize={'cover'}
      backgroundPosition={'25% 50%'}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 6 })}
        bgGradient={'linear(to-t, blackAlpha.400, transparent)'}
      >
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            paddingTop={useBreakpointValue({ base: '150%', md: '0%' })}
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}
          >
            We'll come to you!
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}
