import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Container,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { GiAutoRepair, GiCrowbar, GiFlatTire } from 'react-icons/gi';
import Feature from '../components/Feature';

export default function SplitWithImage() {
  return (
    <Container maxW={'5xl'} id={'about'}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Heading textAlign={'center'}>About us</Heading>
          <Text fontSize={'lg'}>
            We're a family-owned business that specializes in truck tire repair
            across the Philadelphia metropolitan area.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Accordion allowMultiple id="services">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box>
                      <Feature
                        icon={
                          <Icon
                            as={GiFlatTire}
                            color={'yellow.500'}
                            w={5}
                            h={5}
                          />
                        }
                        iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                        text={'Tire Repair'}
                      />
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <Box>Tire Repair</Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton>
                  <Feature
                    icon={
                      <Icon as={GiCrowbar} color={'green.500'} w={5} h={5} />
                    }
                    iconBg={useColorModeValue('green.100', 'green.900')}
                    text={'Tire Maintenance'}
                  />
                </AccordionButton>
                <AccordionPanel>
                  <Box>Tire Maintenance</Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton>
                  <Feature
                    icon={
                      <Icon
                        as={GiAutoRepair}
                        color={'purple.500'}
                        w={5}
                        h={5}
                      />
                    }
                    iconBg={useColorModeValue('purple.100', 'purple.900')}
                    text={'Miscellaneous Repairs'}
                  />
                </AccordionButton>
                <AccordionPanel>
                  <Box>Miscellaneous Repairs</Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Stack>
        <Center>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={'./working.jpeg'}
            objectFit={'cover'}
            boxSize={'md'}
          />
        </Center>
      </SimpleGrid>
    </Container>
  );
}
