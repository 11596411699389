import { Link } from '@chakra-ui/react';
import { Box, Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { FaFacebook } from 'react-icons/fa';

export default function SocialMedia() {
  return (
    <Box>
      <VStack>
        <Link
          href="https://www.facebook.com/profile.php?id=100080956410443"
          target={'_blank'}
          _hover={'none'}
        >
          <Button
            size={'sm'}
            colorScheme={'facebook'}
            leftIcon={<FaFacebook />}
          >
            Facebook
          </Button>
        </Link>
      </VStack>
    </Box>
  );
}
