import { ChatIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/react';
import { Heading, Icon, StackDivider, Text, VStack } from '@chakra-ui/react';
import SocialMedia from '../components/SocialMedia';

export default function ContactUs() {
  return (
    <VStack id="contact" divider={<StackDivider borderColor={'gray.500'} />}>
      <Heading textAlign={'center'} size="xl">
        Contact us
      </Heading>
      <VStack>
        <Icon as={EmailIcon} boxSize={'10'} />
        <Heading size={'md'}>Email</Heading>
        <Link href="mailto: lopeztrucktirerepair@gmail.com">
          lopeztrucktirerepair@gmail.com
        </Link>
      </VStack>
      <VStack>
        <Icon as={PhoneIcon} boxSize={'10'} />
        <Heading size={'md'}>Call</Heading>
        <Text>
          Telephone: <Link href="tel:18563798914">856 379-8914</Link>
        </Text>
      </VStack>
      <VStack>
        <Icon as={ChatIcon} boxSize={10} />
        <Heading size={'md'}>Social</Heading>
        <SocialMedia />
      </VStack>
    </VStack>
  );
}
